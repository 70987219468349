<template>
  <div>
    <shop-breadcrumb style="margin: 0 auto;width: 1200px;" :breadcrumbList="breadcrumbList" :skip="true"></shop-breadcrumb>
    <div class="docx">
      <component :is="switchComponent"></component>
    </div>
  </div>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import shopBreadcrumb from '@/components/layout/shopping/breadcrumb';
import legalDeclarationCN from "@/views/shopping/common/legalDeclarationCN";
import legalDeclarationUS from "@/views/shopping/common/legalDeclarationUS";
import Mixin from "@/components/mixin/common_mixin";

export default {
  name: 'legalDeclaration',
  mixins: [Mixin],
  components: {
    shopBreadcrumb,
    legalDeclarationCN,
    legalDeclarationUS
  },
  data() {
    return {
      breadcrumbList: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005193'),
          path: '/index'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000038'),
          path: '/legalDeclaration'
        }
      ]
    };
  },
  computed: {
    switchComponent() {
     let component = this.languagesVal === 'CN' ? legalDeclarationCN : legalDeclarationUS;
     return component;
    }
  },
  props: {},
  watch: {},
  methods: {},
};
</script>

<style scoped lang="less">
.docx {
  width: 1200px;
  margin: 0 auto 20px auto;
  padding: 38px;
  background-color: #ffffff;
  color: #000;

}
</style>
